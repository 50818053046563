import React from "react"
import { Markup } from "interweave"
import "./PrivacyAndCookiePolicy.scss"

const PrivacyAndCookiePolicy = (props) => {
  
  const BodyContent = (data) => {

    let returnData = data.data.map((value, i) => {

      if(value.slice_type === 'rich_text'){
        return <Markup key={i} content={value.items[0].rich_text.html}></Markup>
      }
      else{
        return <div key={i} dangerouslySetInnerHTML={{ __html: value.items[0].rich_text_html.text}}></div>
      }
    })
    return returnData
  }


  return (
    <section className="cookie-page policy">
      <div className="container">
        <div className="row">
            <div>
              <h1>{props.data.page_title.text}</h1>
              <BodyContent data={props.data.body} />
            </div>
          </div>
      </div>
    </section>
  )
}
export default PrivacyAndCookiePolicy