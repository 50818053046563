import React from "react"
import NavigationBar from "../components/global/NavigationBar/NavigationBar"
import Layout from "../components/Layout/Layout"
import SiteFooter from "../components/global/SiteFooter/SiteFooter"
import PrivacyAndCookiePolicy from "../components/PrivacyAndCookiePolicy/PrivacyAndCookiePolicy"
import { graphql, useStaticQuery } from "gatsby"

const PrivacyAndCookiePolicyPage = () => {
  const data = useStaticQuery(graphql`
    query PrivacyAndCookiePolicyPageQuery {
      prismicBlindcvPrivacyAndCookiePolicyPage {
        data {
          page_title {
            text
            html
          }
          analytics {
            document {
              ... on PrismicBlindcvPageAnalytics {
                id
                data {
                  event
                  page_title
                  page_type
                }
              }
            }
          }
          body {
            ... on PrismicBlindcvPrivacyAndCookiePolicyPageDataBodyRichText {
              id
              items {
                rich_text {
                  html
                }
              }
              slice_type
            }
            ... on PrismicBlindcvPrivacyAndCookiePolicyPageDataBodyRichTextHtml {
              id
              items {
                rich_text_html {
                  text
                }
              }
              slice_type
            }
          }
        }
      }
    }
  `)

  const pageData = data.prismicBlindcvPrivacyAndCookiePolicyPage.data
  return (
    <div>
      <Layout pageViewAnalyticsData={
          data.prismicBlindcvPrivacyAndCookiePolicyPage.data.analytics.document.data
        }>
        <NavigationBar />
        <div className="page-content">
          <PrivacyAndCookiePolicy data={pageData} />
          <SiteFooter />
        </div>
      </Layout>
    </div>
  )
}
export default PrivacyAndCookiePolicyPage;
